import "slick-carousel";

import "slick-carousel/slick/slick.css";

$(document).ready(function() {
  $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $("#homePrev"),
    nextArrow: $("#homeNext"),
    appendDots: $("#homeDots"),
    autoplay: true,
    fade: true
  });
});
